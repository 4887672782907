import { useEffect } from 'react';
import { toast } from 'sonner';
import type { Notification } from '~/types/notification';

export function useNotification(notification?: Notification) {
  useEffect(() => {
    if (!notification) {
      return;
    }

    switch (notification.type) {
      case 'error':
        toast.error(notification.title, {
          duration: notification.duration,
          description: notification.description,
        });
        break;
      case 'success':
        toast.success(notification.title, {
          duration: notification.duration,
          description: notification.description,
        });
        break;
      case 'warning':
        toast.warning(notification.title, {
          duration: notification.duration,
          description: notification.description,
        });
        break;
      case 'info':
      default:
        toast(notification.title, {
          duration: notification.duration,
          description: notification.description,
        });
        break;
    }
  }, [notification]);
}
